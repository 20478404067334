// @flow
import React from 'react';

import { useIsMounted } from 'client/hooks';

type Props = {
	children: React.Node,
};

export function NoSSR(props: Props) {
	const { children } = props;
	const isMounted = useIsMounted();

	return isMounted ? children : null;
}

export default NoSSR;
