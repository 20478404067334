// @flow
import _ from 'lodash';
import { APP_CONFIG } from 'common/constants';

const DEFAULT_API_URL = APP_CONFIG.domains.backend.url;
// const CDN_API_URL = APP_CONFIG.cdn.backend;

class ApiWorker {
	_token: string;
	// _apiURL: string;

	// constructor(api URL: string) {
	constructor() {
		this.token = '';
		// this._apiURL = apiURL;
	}

	// get apiURL() {
	// 	return this._apiURL;
	// }

	get token() {
		return this._token;
	}

	set token(val: string) {
		this._token = val;
	}

	getResultFrame = <R>(): BackEndResponse<R> => {
		return { success: false, error: '' };
	};

	getResFrameFromError = <R>(e: Error): BackEndResponse<R> => {
		const result = this.getResultFrame<R>();

		result.error = JSON.parse(e.message || 'something went wrong');
		return result;
	};

	send = async <R>({ url, method, body = null, secure = false }: ApiWorkerParams): Promise<BackEndResponse<R>> => {
		const targetUrl = `${DEFAULT_API_URL}${url}`;
		const headers = { 'Content-Type': 'application/json; charset=utf-8' };
		const options = { mode: 'cors' };
		let result = this.getResultFrame<R>();

		if (secure) {
			_.set(headers, 'Authorization', `Bearer ${this.token}`);
		}

		_.set(options, 'method', method);
		_.set(options, 'headers', headers);

		if (method !== 'GET') {
			_.set(options, 'body', JSON.stringify(body));
		}

		try {
			const response = await fetch(targetUrl, options);
			const jsonResp = await response.json();

			if (response.status === 200 || response.status === 204) {
				result.success = true;
				result.data = jsonResp.data;
			} else {
				result.error = jsonResp.error || jsonResp.message || 'Something went wrong';
			}
		} catch (e) {
			result = this.getResFrameFromError<R>(e);
		}

		return result;
	};
}

// const defaultApi = new ApiWorker(CDN_API_URL);
// const noCDNApi = new ApiWorker(DEFAULT_API_URL);

export default new ApiWorker();
// export { noCDNApi };
