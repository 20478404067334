// @flow
import produce from 'immer';
import _ from 'lodash';
import { COLLECTIONS } from 'client/constants';
import * as actions from 'client/constants/actions';

type Action = {
	type: string,
	payload: any,
};

type Celebs = {
	data: {
		list: CelebItem[],
		total: number,
		collection: string,
	},
	isBusy: false,
};

const initialState: Celebs = {
	isBusy: false,
	data: { total: 0, list: [], collection: '' },
};

export default function CelebsReducers(state: Celebs = initialState, { type, payload }: Action) {
	return produce(state, draft => {
		switch (type) {
			case actions.GET_ITEMS.START:
				if (payload.collection === COLLECTIONS.CELEBS) {
					_.set(draft, 'isBusy', true);
				}
				break;

			case actions.GET_ITEMS.SUCCESS:
				if (payload.collection === COLLECTIONS.CELEBS) {
					_.set(draft, 'data', payload);
					_.set(draft, 'isBusy', false);
				}
				break;

			case 'get_full_celebs_data_success': {
				_.each(payload.items, item => {
					const currentItemIndex = draft.data.items.findIndex(i => i.id === item.id);
					if (currentItemIndex !== -1) {
						draft.data.items[currentItemIndex] = item;
					}
				});
				break;
			}

			case actions.GET_ITEMS.FAIL:
				if (payload.collection === COLLECTIONS.CELEBS) {
					_.set(draft, 'isBusy', false);
				}
				break;

			default:
				break;
		}
	});
}
