/* eslint-disable indent */
// @flow

import React, { useState } from 'react';
import _ from 'lodash';
import { Responsive } from 'client/components/common';

// import { coverFileLink } from 'common/utils/cover-file-link';
// import { fileLink } from 'common/utils/file-link';
import { CAN_USE_DOM, DEFAULT_IMAGE } from 'common/constants';
import { fileLinkIMGIX } from 'common/utils/file-link-imgix';
import { Icon } from 'client/components/common/Icon';
import { useIsMounted } from 'client/hooks';
import bgDesktop from 'assets/client/timeline-background.svg';
import TimelineItem from './TimelineItem';
import TimelineBottom from './TimelineBottom';
import TimelineMobile from './TimelineMobile';
import css from './Timeline.scss';

type Props = {
	items: Array<CoverItem>,
};
export default function Timeline(props: Props) {
	const { items } = props;
	const isMounted = useIsMounted();
	const [activeIndex, setActiveIndex] = useState(_.random(items.length - 1));
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const isTablet = Responsive.isMatching(Responsive.TABLET_L) || Responsive.isMatching(Responsive.TABLET_P);
	const sortItems = _.reverse(_.sortBy(items, ['year']));
	const imageParams = '?fit=clip&w=380&h=500&q=70';

	const setIndex = (index: number) => {
		setActiveIndex(index);
	};

	const renderImageSRC = i => {
		return !_.isEmpty(i.uploadedCover)
			? fileLinkIMGIX(i.uploadedCover, imageParams)
			: `https://laishacovers.imgix.net/covers/LAI${i.fileName}.jpg${imageParams}`;
	};

	return (
		<div className={css.timeline}>
			{isMounted && !isDesktop && !isTablet && (
				<Icon type="timeline-background-mobile" className={css.background} />
			)}
			<div
				className={css.backgroundColor}
				style={
					isDesktop || isTablet
						? {
								backgroundImage: `url(${bgDesktop})`,
								backgroundColor: sortItems[activeIndex]?.color || '#f4c7d3',
						  }
						: { backgroundColor: sortItems[activeIndex]?.color || '#f4c7d3' }
				}
			></div>
			{isDesktop || isTablet ? (
				<div className={css.contentWrapper}>
					<>
						{_.map(sortItems, (item, index) => {
							return (
								activeIndex === index && (
									<TimelineItem
										cover={CAN_USE_DOM ? renderImageSRC(item) : DEFAULT_IMAGE}
										content={item.timelineContent}
										year={item.year}
										textColor={item.textColor}
										title={item.title}
										key={item.id}
										id={item.id}
										modelName={item.modelName}
									/>
								)
							);
						})}
					</>
					<TimelineBottom items={sortItems} setIndex={setIndex} activeIndex={activeIndex} />
				</div>
			) : (
				<TimelineMobile items={sortItems} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
			)}
		</div>
	);
}
