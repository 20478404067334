// @flow

import React from 'react';
// import { fileLink } from 'common/utils';
import { fileLinkIMGIX } from 'common/utils/file-link-imgix';
import { NoSSR } from 'client/components/common/NoSSR';
import { Icon } from 'client/components/common/Icon';
import cn from 'classnames';
// import { CAN_USE_DOM } from 'common/constants';

import css from './VodSection.scss';

type Props = {|
	item: typeof VodItem,
	onSelect: Function,
	selected: string,
|};
export default function VodItem(props: Props) {
	const { item, onSelect, selected } = props;
	const { title } = item;
	const videoTitle = title.length > 40 ? `${title.substring(0, 40)}...` : title;
	const imageParams = '?fit=clip&w=400&h=220&q=70';

	return (
		<div
			className={cn(css.item, selected === item.id ? css.selected : '')}
			style={{ background: `url(${fileLinkIMGIX(item.videoCoverImage, imageParams)})` }}
			onClick={() => onSelect(item.id)}
		>
			<div className={css.bottom}>
				<h4 className={css.videoTitle}>{videoTitle}</h4>
				<NoSSR>
					<Icon className={css.playIcon} type="play-button" />
				</NoSSR>
			</div>
		</div>
	);
}
