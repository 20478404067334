// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter, Switch } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { detectIE } from 'common/utils/detect-ie';

import { ROOT_ROUTE, COVER_PAGE_ROUTE, COVERS_LIST_ROUTE } from 'client/constants';
import { getFullCatalog, getFullMainData } from 'client/actions';
import Main from 'client/components/pages/Main';
import CoverPage from 'client/components/pages/CoverPage';
import CoversList from 'client/components/pages/CoversList';
import * as css from './App.scss';

type Props = {|
	history: RouterHistory,
	location: RouterLocation,
	isBusy?: boolean,
	match: any,
	getFullCatalog: () => Promise<any>,
	getFullMainData: () => Promise<any>,
|};

class App extends React.PureComponent<Props> {
	static defaultProps = {
		isBusy: true,
	};

	async componentDidMount() {
		const isIE = detectIE();

		if (!this.props.isBusy) {
			this.removeRootPreloader();
		}

		if (document && document.body) {
			document.body.setAttribute('data-browser', isIE ? 'ie' : 'not-ie');
		}

		this.props.getFullCatalog();
		this.props.getFullMainData();
	}

	async componentDidUpdate(prevProps: Props) {
		if (!this.props.isBusy && prevProps.isBusy) {
			this.removeRootPreloader();
		}
	}

	renderHelmet = () => {
		const title = 'ארכיון שערי לאשה';
		// eslint-disable-next-line max-len
		const description = `מגזין לאשה מציג ארכיון ייחודי של כל שעריו משנת 1947 עד היום. בואו להיזכר ברגעים הכי גדולים ולסייר בין דמויות בלתי נשכחות`;
		// const shareTitle = '';
		// const shareDescription = 'share description';
		const canonicalURl = 'https://laishacovers.ynet.co.il';
		const shareImageURL = `${canonicalURl}/assets/client/share_1.jpg`;

		return (
			<Helmet
				title={title}
				link={[
					{ rel: 'icon', type: 'image/png', href: 'assets/favicon.png' },
					{ rel: 'canonical', href: canonicalURl },
				]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: title },
					{ property: 'og:description', content: description },
					{ property: 'og:image', content: shareImageURL },
				]}
			/>
		);
	};

	removeRootPreloader = () => {
		const preloadingScreen: ?HTMLElement = document.querySelector('.preloading-screen');

		if (preloadingScreen) {
			preloadingScreen.addEventListener('transitionend', (e: TransitionEvent) => {
				setTimeout(() => {
					if (document && document.body && e.target === preloadingScreen) {
						document.body.removeChild(preloadingScreen);
					}
				}, 1000);
			});
			preloadingScreen.style.opacity = '0';
			preloadingScreen.style.zIndex = '-1000';
		}
	};

	renderRoute = (Component: Function) => {
		return ({ match, ...props }) => {
			return <Component {...props} in={match !== null} />;
		};
	};

	render() {
		return (
			<div id="app" className={css.app}>
				{this.renderHelmet()}
				<div className={css.content}>
					<Switch>
						<Route exact path={ROOT_ROUTE}>
							{({ match, ...props }) => <Main location={props.location} in={!!match} />}
						</Route>
						<Route exact path={COVER_PAGE_ROUTE}>
							{({ match, history, location, ...props }) => (
								<CoverPage match={match} history={history} location={location} in={!!match} />
							)}
						</Route>
						<Route exact path={COVERS_LIST_ROUTE}>
							{({ match, ...props }) => <CoversList match={match} in={!!match} />}
						</Route>
					</Switch>
				</div>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({
	isBusy: false,
});

const mapDispatch = {
	getFullCatalog,
	getFullMainData,
};

export default withRouter(connect(mapState, mapDispatch)(App));
