// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';

// import { coverFileLink } from 'common/utils/cover-file-link';
// import { fileLink } from 'common/utils/file-link';
import { fileLinkIMGIX } from 'common/utils/file-link-imgix';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import { Responsive, isMobileOrTablet } from 'client/components/common/Responsive';
import Socialls from 'client/components/common/Socialls';
import { Icon } from 'client/components/common';
import { CAN_USE_DOM, DEFAULT_IMAGE } from 'common/constants';
import { /* ROOT_ROUTE, */ COVER_PAGE_ROUTE } from 'client/constants';

import * as css from './CoverPage.scss';

type Props = {|
	...EnhancedProps,
	in: boolean,
	covers: CoverItem[],
	match: RouterMatch,
	history: RouterHistory,
	location: RouterLocation,
	currentCover: CoverItem,
|};

type State = {
	currentCover: CoverItem | null,
	filteredCovers: CoverItem[],
	isScrolledContent: boolean,
	touchStartPoint: number,
	nextPrevIndex: CoverItem[],
};

class CoverPage extends React.PureComponent<Props, State> {
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		if (nextProps.covers !== prevState.filteredCovers) {
			return {
				filteredCovers: nextProps.covers,
			};
		}

		return null;
	}

	transitionCompleteTimeout: TimeoutID;

	isDesktop = !isMobileOrTablet();
	isUrlParamsFiltered = false;

	state = {
		currentCover: this.props.currentCover,
		filteredCovers: this.props.covers,
		isScrolledContent: false,
		touchStartPoint: 0,
		nextPrevIndex: [],
	};

	componentDidMount() {
		const { onTransitionComplete /* , match, covers */ } = this.props;

		// const { filteredCovers } = this.state;
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));

		// console.info(filteredCovers);
		// const current = _.find(covers, { id: match.params.id });
		// const index = _.findIndex(filteredCovers, i => {
		// 	return i.id === match.params.id;
		// });

		// this.nextPrev(index);
		// this.setState({ currentCover: current });
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}

		if (this.state.filteredCovers !== prevState.filteredCovers && !this.isUrlParamsFiltered) {
			this.filterByURLParams();
			this.isUrlParamsFiltered = true;
		}
	}

	parseURL = () => {
		const { location } = this.props;
		if (!location.search) {
			return;
		}

		const query = location.search.split('?')[1];
		const paramsObj = new URLSearchParams(query);

		if (query) {
			return [...paramsObj].reduce((obj, [key, val]) => {
				obj[key] = val;
				return obj;
			}, {});
		}
	};

	filterByURLParams = () => {
		const params = this.parseURL();
		const { filteredCovers } = this.state;
		// console.info(filteredCovers);
		if (params?.search) {
			const filteredItems = _.filter(filteredCovers, (item: CoverItem) => {
				const searchStr = params.search.toLowerCase();
				const tagsString = item.tags.join(',').toLowerCase();
				return (
					(item.title && item.title.toLowerCase().includes(searchStr)) ||
					(tagsString && tagsString.includes(searchStr))
				);
			});

			// console.info(filteredItems);
			this.initialIndex(filteredItems);
			this.setState({ filteredCovers: filteredItems });
		}

		if (params?.year && params.months) {
			const date = {
				year: params.year,
				months: params.months.length >= 1 ? params.months.split(',') : [params.months],
			};

			const filteredByDate = _.reduce(
				filteredCovers,
				(acc, i, key) => {
					if (i.year === date.year && date.months.includes(i.month)) {
						acc.push(i);
					}
					return acc;
				},
				([]: CoverItem[])
			);

			this.initialIndex(filteredByDate);
			this.setState({ filteredCovers: filteredByDate });
		}

		if (params?.tag) {
			const filteredByTag = _.reduce(
				filteredCovers,
				(acc, i, key) => {
					if (i.tags.includes(params?.tag)) {
						acc.push(i);
					}
					return acc;
				},
				([]: CoverItem[])
			);

			this.initialIndex(filteredByTag);
			this.setState({ filteredCovers: filteredByTag });
		}

		this.initialIndex(filteredCovers);
	};

	initialIndex = (filteredCovers: CoverItem[]) => {
		const index = _.findIndex(filteredCovers, i => {
			return i.id === this.props.match.params.id;
		});

		// console.info('filteredCovers', filteredCovers, index);
		this.nextPrev(index, filteredCovers);
	};

	cleanText = (htmlStr: string) => {
		return htmlStr ? htmlStr.replace(/(<([^>]+)>)/gi, '') : '';
	};

	renderHelmet = () => {
		const { currentCover } = this.props;

		const title = `${currentCover.modelName} - ארכיון שערי לאישה`;
		// eslint-disable-next-line max-len
		const description = `${currentCover.modelName} - ארכיון שערי לאישה. ${this.cleanText(currentCover.content)}`;
		// const shareTitle = '';
		// const shareDescription = 'share description';
		const originalUrl = 'https://laishacovers.ynet.co.il/';
		const canonicalURl = `${originalUrl}${currentCover.id}`;
		const shareImageURL = `${originalUrl}assets/client/share_1.jpg`;

		return (
			<Helmet
				title={title}
				link={[
					{ rel: 'icon', type: 'image/png', href: `${originalUrl}assets/favicon.png` },
					{ rel: 'canonical', href: canonicalURl },
				]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: title },
					{ property: 'og:description', content: description },
					{ property: 'og:image', content: shareImageURL },
				]}
			/>
		);
	};

	nextPrev = (index, covers) => {
		// const { filteredCovers: covers } = this.state;
		let indexPrev = null;
		let indexNext = null;

		if (!_.isEmpty(covers[index - 1])) {
			indexPrev = covers[index - 1];
		} else {
			indexPrev = covers[covers.length - 1];
		}
		if (!_.isEmpty(covers[index + 1])) {
			indexNext = covers[index + 1];
		} else {
			indexNext = covers[covers.length + 1];

			if (!indexNext) {
				indexNext = covers[covers.length - 1];
			}
		}

		this.setState({ nextPrevIndex: [indexPrev, indexNext] });
	};

	// Scale content on touch
	handleTouchStart = e => {
		this.setState({ touchStartPoint: _.get(e, 'changedTouches[0].clientY', 0) });
	};

	handleTouchEnd = e => {
		const endPoint = _.get(e, 'changedTouches[0].clientY', 0);
		this.handleTouchMove(endPoint, this.state.touchStartPoint);
	};

	handleTouchMove = (endPoint, startPoint) => {
		if (endPoint < startPoint) {
			this.setState({ isScrolledContent: true });
		} else {
			this.setState({ isScrolledContent: false });
		}
	};

	onCloseClick = () => {
		// const { history } = this.props;
		// history.push(ROOT_ROUTE);
		if (CAN_USE_DOM) {
			window.open(window.location.origin, '_self');
		}
	};

	onPrevNextButtonClick = e => {
		const { index } = e.currentTarget.dataset;
		const { history } = this.props;
		const { nextPrevIndex, filteredCovers: covers } = this.state;

		this.setState({ currentCover: nextPrevIndex[+index] });
		const currentIndex = _.findIndex(covers, i => {
			return i.id === nextPrevIndex[+index].id;
		});

		this.nextPrev(currentIndex, covers);
		history.push(COVER_PAGE_ROUTE.replace(':id', _.get(nextPrevIndex[+index], 'id')));
	};

	renderImageSRC = i => {
		const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
		const imageParams = isDesktop ? '?fit=clip&w=580&h=890&q=8500&q=70' : '?fit=clip&w=275&h=400&q=8500&q=70';

		return !_.isEmpty(i.uploadedCover)
			? fileLinkIMGIX(i.uploadedCover, imageParams)
			: `https://laishacovers.imgix.net/covers/LAI${i.fileName}.jpg${imageParams}`;
	};

	render() {
		const { transitionState } = this.props;
		const { currentCover, isScrolledContent } = this.state;
		const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
		const creditTitle = 'צילום';

		return (
			<div className={classNames(css.coverPage, css[transitionState])}>
				{this.renderHelmet()}
				<div
					className={classNames(css.wrapper, isScrolledContent && css.isScrolling)}
					onTouchStart={!isDesktop ? this.handleTouchStart : null}
					onTouchEnd={!isDesktop ? this.handleTouchEnd : null}
				>
					<Icon type="close-icon" onClick={this.onCloseClick} className={css.closeIcon} />
					<div className={css.slideWrapper}>
						<div className={css.slide}>
							<div className={css.slideContent}>
								{!isDesktop && (
									<Socialls className={css.socials} options={{ url: `${currentCover.id}/` }} />
								)}
								<div className={css.topTextWrapper}>
									<h1 className={css.slideTitle}>{currentCover.modelName}</h1>
									<div className={css.date}>{currentCover.date}</div>
								</div>
								<div className={css.textWrapper}>
									<div
										className={css.text}
										dangerouslySetInnerHTML={{ __html: currentCover.content }}
									/>
								</div>
								{isDesktop && (
									<Socialls className={css.socials} options={{ url: `${currentCover.id}/` }} />
								)}
							</div>
							<div className={css.coverWrap}>
								<div className={css.coverImage}>
									{/* eslint-disable */}
									<img
										src={CAN_USE_DOM ? this.renderImageSRC(currentCover) : DEFAULT_IMAGE}
										alt={currentCover.modelName}
									/>
									{/* eslint-enable */}
								</div>
								<div className={css.credits}>
									{creditTitle}
									{' : '}
									{`"${currentCover.photoCredit}"`}
								</div>
							</div>
						</div>
					</div>
					<div className={css.navigation}>
						<div className={css.buttonPrev} data-index="1" onClick={this.onPrevNextButtonClick}>
							<div className={css.buttonPrevArrow} />
							<div className={css.navLabel}>הקודם</div>
						</div>
						<div className={css.buttonNext} data-index="0" onClick={this.onPrevNextButtonClick}>
							<div className={css.navLabel}>הבא</div>
							<div className={css.buttonNextArrow} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTransition(CoverPage);
