/* eslint-disable jsx-a11y/media-has-caption */
// @flow

import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CAN_USE_DOM } from 'common/constants';
import { Icon } from 'client/components/common/Icon';
import { Responsive } from 'client/components/common';
import { dataLayerEvents } from 'common/utils/gtag';
import playButton from 'assets/client/Vod/play-button.png';
import { fileLink } from 'common/utils';
import { fileLinkIMGIX } from 'common/utils/file-link-imgix';
import VodItem from './VodItem';
import css from './VodSection.scss';

type Props = {|
	items: any,
|};

export default function VodSection(props: Props) {
	const { items } = props;
	const video = useRef();
	const [selected, setSelected] = useState('');
	const [swiper, setSwiper] = useState(null);
	const [activeSlide, setActiveSlide] = useState(0);
	const [selectedItem, setSelectedItem]: any = useState(items[0]);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isFirstView, setIsFirstView] = useState(true);

	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const SLIDES_IN_VIEW = isDesktop ? 5 : 2;
	const imagePosterParams = isDesktop ? '?fit=clip&w=1280&h=720&q=70' : '?fit=clip&w=375&h=210&q=70';

	const onSelect = id => {
		const si = _.find(items, ['id', id]);

		if (id !== selectedItem.id) {
			setSelectedItem(si);
			setSelected(id);
			setIsPlaying(true);
			setIsFirstView(false);
			if (si) {
				dataLayerEvents.onVODClick(si.title);
			}

			setTimeout(() => {
				if (video.current) {
					video.current.play();
				}
			}, 500);
		}
	};

	const onPlayClick = () => {
		if (video.current) {
			video.current.play();
			setIsPlaying(true);
		}
	};

	const onVideoClick = () => {
		if (isPlaying) {
			if (video.current) {
				video.current.pause();
			}
			setIsPlaying(false);
		} else {
			if (video.current) {
				video.current.play();
			}
			setIsPlaying(true);
		}
	};

	const onSlideChange = () => {
		if (swiper !== null) {
			setActiveSlide(swiper.activeIndex);
		}
	};

	const onNextButtonClick = () => {
		if (swiper) {
			swiper.slideNext();
		}
	};

	const onPrevButtonClick = () => {
		if (swiper) {
			swiper.slidePrev();
		}
	};

	const videoEnded = () => {
		setIsPlaying(false);
	};

	useEffect(() => {
		if (video.current) {
			video.current.addEventListener('ended', () => {
				videoEnded();
			});
		}
	}, []);

	return (
		<div className={css.vodSection}>
			<div className={css.topGradient}></div>
			<div className={cn(css.header, isPlaying && css.hideHeader)}>
				<h4 className={css.title}> VOD: מאחורי הקלעים של השערים</h4>
				<div>
					<p className={css.videoTitle}>{selectedItem.title}</p>
					<p className={css.videoTitle}>{selectedItem.videoDescription}</p>
				</div>
			</div>
			{CAN_USE_DOM && (
				<>
					{isDesktop ? (
						<div className={css.videoWrapper}>
							<div className={css.videoOverlay} onClick={onVideoClick} />
							<video
								ref={video}
								src={fileLink(selectedItem.videoFile)}
								className={css.video}
								poster={
									isFirstView ? fileLinkIMGIX(selectedItem.videoCoverImage, imagePosterParams) : ''
								}
								controls
								preload="none"
							></video>
						</div>
					) : (
						<div className={css.videoWrapper}>
							<video
								ref={video}
								src={fileLink(selectedItem.videoFile)}
								className={css.video}
								onClick={onVideoClick}
								poster={
									isFirstView ? fileLinkIMGIX(selectedItem.videoCoverImage, imagePosterParams) : ''
								}
								preload="none"
							></video>
						</div>
					)}
				</>
			)}

			<img
				src={playButton}
				alt="Play"
				className={css.playButton}
				onClick={onPlayClick}
				style={{ display: isPlaying ? 'none' : 'block' }}
			/>

			<div className={cn(css.items, isPlaying && isDesktop ? css.hideItems : '')}>
				<Swiper
					className={css.swiper}
					slidesPerView="auto"
					spaceBetween={20}
					initialSlide={0}
					pagination
					navigation={{
						prevEl: `${css.prevArrow}`,
						nextEl: `${css.nextArrow}`,
					}}
					onSwiper={setSwiper}
					onSlideChange={onSlideChange}
				>
					{_.map(_.orderBy(items, 'orderData', 'asc'), item => {
						return (
							<SwiperSlide className={css.swiperSlide} key={item.id}>
								<VodItem item={item} onSelect={onSelect} selected={selected} key={item.id} />
								<div className={css.bottomGradientItem}></div>
							</SwiperSlide>
						);
					})}
					<div
						onClick={onNextButtonClick}
						className={cn(css.nextArrow, activeSlide >= items.length - SLIDES_IN_VIEW ? '' : css.visible)}
					>
						<Icon type="right-arrow" color="white" />
					</div>
					<div onClick={onPrevButtonClick} className={cn(css.prevArrow, activeSlide >= 1 ? css.visible : '')}>
						<Icon type="right-arrow" className={css.rightIcon} color="white" />
					</div>
				</Swiper>
			</div>
			<div className={cn(css.bottomGradient, isPlaying && css.lightGradient)}></div>
		</div>
	);
}
