/* eslint-disable no-unused-vars */
// @flow
/* eslint-disable react/no-danger */

import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { coverFileLink } from 'common/utils/cover-file-link';
// import { fileLink } from 'common/utils/file-link';
import { fileLinkIMGIX } from 'common/utils/file-link-imgix';
import { COVER_PAGE_ROUTE } from 'client/constants';
import { CAN_USE_DOM, DEFAULT_IMAGE } from 'common/constants';
import { Responsive, Icon } from 'client/components/common';
import { isMobileOrTablet } from 'client/components/common/Responsive';
import SwiperCore, { EffectCoverflow, Navigation, Lazy } from 'swiper';
import Socialls from '../../Socialls';
import CoverPopUp from '../../CoverPopUp';
import css from './Gallery.scss';

SwiperCore.use([EffectCoverflow, Navigation, Lazy]);

type Props = {|
	items: CoverItem[],
|};

const Gallery = ({ items }: Props) => {
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	// const TOTAL = _.size(items);
	// const LOADED_SIZE = isDesktop ? 5 : 3;
	// const MAX_LOADED_COUNT = Math.ceil(TOTAL / LOADED_SIZE);
	const SLIDES_PER_VIEW = isDesktop ? 5 : 3;
	const INITIAL_SLIDE = isDesktop ? 2 : 1;
	const INITIAL_SLIDES_SIZE = Math.min(30, _.size(items) - 1);
	const isTablet = isMobileOrTablet();
	/* eslint-disable */
	const STRETCH_PARAM = CAN_USE_DOM
		? window.innerWidth > 1280
			? 52
			: isTablet
			? 50
			: (52 / 1280) * window.innerWidth
		: 0;
	/* eslint-enable */
	const imageParams = isDesktop ? '?fit=clip&w=350&h=505&q=70' : '?fit=clip&w=210&h=300&q=70';

	// const [loadsClickCounter, setLoadsClickCounter] = useState(INITIAL_SLIDES_SIZE / SLIDES_PER_VIEW);
	const initialSlides = items.slice(0, INITIAL_SLIDES_SIZE);
	// const [loadedSlides, setLoadedSlides] = useState(initialSlides);

	const [swiper, setSwiper] = useState(null);
	const [popupSwiper, setPopupSwiper] = useState(null);
	const [popupInitialSlide, setPopupInitialSlide] = useState(null);
	const [openPopup, setOpenPopup] = useState(null);
	const [isScrolledContent, setIsScrolledContent] = useState(false);
	const [touchStartPoint, setTouchStartPoint] = useState(0);

	const socialsText = 'שתפי';

	const renderImageSRC = i => {
		return !_.isEmpty(i.uploadedCover)
			? fileLinkIMGIX(i.uploadedCover, imageParams)
			: `https://laishacovers.imgix.net/covers/LAI${i.fileName}.jpg${imageParams}`;
	};

	const renderMainSlide = (i: CoverItem, index: number) => {
		return (
			<div className={css.slide}>
				<div className={css.coverWrap}>
					{/* eslint-disable-next-line react/jsx-no-target-blank */}
					<a
						href={COVER_PAGE_ROUTE.replace(':id', i.id)}
						target="_blank"
						title={i.title || i.content}
						onClick={e => e.preventDefault()}
					>
						<img
							data-src={CAN_USE_DOM === false ? DEFAULT_IMAGE : undefined}
							src={CAN_USE_DOM ? renderImageSRC(i) : undefined}
							alt={i.title || i.content}
							className="swiper-lazy"
						/>
					</a>
				</div>
				{isDesktop && (
					<div className={css.slideHover}>
						<div className={css.slideHoverContent}>
							<div className={css.stideTitle}>
								{i.modelName}, {i.date}
							</div>
							<div className={css.slideSubtitle}>{i.title}</div>
							<div className={css.socials}>
								<span className={css.socialsText}>{socialsText}</span>
								<Socialls options={{ url: `${i.id}/` }} />
							</div>
						</div>
					</div>
				)}
			</div>
		);
	};

	const onSlideChange = () => {
		// if (swiper !== null && swiper.activeIndex % LOADED_SIZE === 0) {
		// 	const counter = loadsClickCounter + 1;
		// 	const slidesSize = _.size(loadedSlides);
		// 	const newSlides = items.slice(slidesSize, counter * LOADED_SIZE);
		// 	if (counter <= MAX_LOADED_COUNT) {
		// 		setLoadsClickCounter(counter);
		// 		setLoadedSlides([...loadedSlides, ...newSlides]);
		// 	}
		// }
	};

	/*
		Popup Swiper functions
	* */

	const onSlideClick = (slideIndex: number) => {
		setPopupInitialSlide(slideIndex);
		setOpenPopup(true);
	};

	const onPopupNextButtonClick = () => {
		if (popupSwiper) {
			popupSwiper.slideNext();
		}
	};

	const onPopupPrevButtonClick = () => {
		if (popupSwiper) {
			popupSwiper.slidePrev();
		}
	};

	const onPopupSlideChange = () => {
		// if (popupSwiper !== null && popupSwiper.activeIndex === _.size(loadedSlides) - 1) {
		// 	const counter = loadsClickCounter + 1;
		// 	const slidesSize = _.size(loadedSlides);
		// 	const newSlides = items.slice(slidesSize, counter * LOADED_SIZE);
		// 	if (counter <= MAX_LOADED_COUNT) {
		// 		setLoadsClickCounter(counter);
		// 		setLoadedSlides([...loadedSlides, ...newSlides]);
		// 	}
		// }
	};

	// Scale popup content on touch
	const handleTouchStart = e => {
		setTouchStartPoint(_.get(e, 'changedTouches[0].clientY', 0));
	};

	const handleTouchEnd = e => {
		const endPoint = _.get(e, 'changedTouches[0].clientY', 0);
		handleTouchMove(endPoint, touchStartPoint);
	};

	const handleTouchMove = (endPoint, startPoint) => {
		if (endPoint < startPoint) {
			setIsScrolledContent(true);
		} else {
			setIsScrolledContent(false);
		}
	};

	const onPopupCloseClick = () => {
		if (swiper && popupSwiper) {
			if (isDesktop) {
				swiper.slideTo(popupSwiper.realIndex + 5);
			} else {
				swiper.slideTo(popupSwiper.realIndex + 3);
			}
		}
		setOpenPopup(false);
	};

	return (
		<div className={css.gallery}>
			<div className={css.gallerySliderWrapper}>
				<Swiper
					effect="coverflow"
					grabCursor
					centeredSlides
					lazy={{ loadPrevNext: true }}
					slidesPerView={SLIDES_PER_VIEW}
					initialSlide={INITIAL_SLIDE}
					navigation
					speed={1000}
					loop
					coverflowEffect={{
						rotate: 0,
						stretch: STRETCH_PARAM,
						depth: 0,
						modifier: 1,
						slideShadows: false,
					}}
					onSlideChange={onSlideChange}
					onSwiper={setSwiper}
					className={css.gallerySlider}
				>
					{_.map(initialSlides, (i, slideIndex) => {
						return (
							<SwiperSlide className={css.slide} key={i.id} onClick={() => onSlideClick(slideIndex)}>
								{renderMainSlide(i, slideIndex)}
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>

			{openPopup && (
				<div
					className={classNames(css.popupSliderWrapper, isScrolledContent && css.isScrolling)}
					onTouchStart={!isDesktop ? handleTouchStart : null}
					onTouchEnd={!isDesktop ? handleTouchEnd : null}
				>
					<Icon type="close-icon" onClick={onPopupCloseClick} className={css.closeIcon} />
					<Swiper
						slidesPerView={1}
						initialSlide={popupInitialSlide}
						speed={1000}
						onSwiper={setPopupSwiper}
						onSlideChange={onPopupSlideChange}
						lazy={{ loadPrevNext: true }}
						navigation={{
							nextEl: `${css.popupButtonNext}`,
							prevEl: `${css.popupButtonPrev}`,
						}}
						className={css.popupSlider}
					>
						{_.map(initialSlides, i => {
							return (
								<SwiperSlide className={css.slide} key={i.id}>
									<CoverPopUp item={i} type="slide" isScrolling={isScrolledContent} />
								</SwiperSlide>
							);
						})}
					</Swiper>
					<div className={css.popupNavigation}>
						<div className={css.popupButtonPrev} onClick={onPopupPrevButtonClick}>
							<div className={css.popupButtonPrevArrow} />
							<div className={css.navLabel}>הקודם</div>
						</div>
						<div className={css.popupButtonNext} onClick={onPopupNextButtonClick}>
							<div className={css.navLabel}>הבא</div>
							<div className={css.popupButtonNextArrow} />
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Gallery;
