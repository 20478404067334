// @flow
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';
// import { coverFileLink } from 'common/utils/cover-file-link';
// import { fileLink } from 'common/utils/file-link';
import { fileLinkIMGIX } from 'common/utils/file-link-imgix';
import { CAN_USE_DOM, IS_DEVEL, DEFAULT_IMAGE } from 'common/constants';
import { COVER_PAGE_ROUTE } from 'client/constants';
import { Button, Responsive, Icon, NoSSR } from 'client/components/common';
import calendarIcon from 'assets/client/Catalog/calendar-icon.png';
import tagIcon from 'assets/client/Catalog/tag-icon.png';
import { dataLayerEvents } from 'common/utils/gtag';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Navigation, Lazy } from 'swiper';

import Search from './Search';
import DatePickerPopup, { MONTHS } from './DatePickerPopup';
import TagsPopup from './TagsPopup';
import Socialls from '../../Socialls';
import CoverPopUp from '../../CoverPopUp';
import css from './Catalog.scss';

SwiperCore.use([EffectCoverflow, Navigation, Lazy]);

type Props = {
	items: CoverItem[],
	settings: SettingsType,
};

type SelectedDate = {
	year: string,
	months: string[],
};

const NO_DATA = 'לא נמצאו תוצאות';
const POP_UP_TYPES = {
	DATEPICKER: 'datepicker',
	TAGS: 'tags',
};

const Catalog = ({ items, settings }: Props) => {
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const history = useHistory();
	const TOTAL = _.size(items);
	const LOADED_SIZE = isDesktop ? 12 : 9; // BY ONE CLICK ON LOAD MORE
	const MAX_LOADED_COUNT = Math.ceil(TOTAL / LOADED_SIZE);
	// const MAX_TAGS_SIZE = isDesktop ? 13 : 9;

	const [isDatepickerPopupOpened, setIsDatepickerPopupOpened] = useState<boolean>(false);
	const [isTagsPopupOpened, setIsTagsPopupOpened] = useState<boolean>(false);
	const [loadsClickCounter, setLoadsClickCounter] = useState<number>(1);
	const [selectedDate, setSelectedDate] = useState<SelectedDate>({ months: [], year: '' });
	const [selectedTag, setSelectedTag] = useState<string>('');
	const [filteredList, setFilteredList] = useState<CoverItem[]>(items ? items.slice(0, LOADED_SIZE) : []);
	const [totalFiltered, setTotalFiltered] = useState<CoverItem[]>(items);
	const [isEnabledLoadMore, setIsEnabledLoadMore] = useState<boolean>(true);
	const [allTags, setAllTags] = useState([]);
	const [autocompleteArray, setAutocompleteArray] = useState([]);

	const [activePopupCover, setActivePopupCover] = useState<boolean>(false);
	const [popupSwiper, setPopupSwiper] = useState(null);
	const [isScrolledContent, setIsScrolledContent] = useState(false);
	const [touchStartPoint, setTouchStartPoint] = useState(0);
	const initialSlides = filteredList;
	const [popupInitialSlide] = useState(null);

	const imageParams = isDesktop ? '?fit=clip&w=280&h=375&q=70' : '?fit=clip&w=105&h=140&q=70';
	const tags = isDesktop ? _.chunk(settings.tagsDesktop, 7) : _.chunk(settings.tagsMobile, 3);
	const title = 'חפשו שערים אהובים';
	const placeholder = 'לפי תאריך';
	const text = selectedDate.year || placeholder;
	const tagsButton = 'לפי תגית';
	const loadMoreButtonLabel = 'טען עוד';
	const socialsText = 'שתפי';
	const searchSocialsText = 'שתפו את תוצאות החיפוש';

	useEffect(() => {
		setTotalFiltered(items);
	}, [items]);

	useEffect(() => {
		if (!settings.tagsPopup) {
			const allTagsList = _.reduce(
				items,
				(acc, i, key) => {
					if (!_.isEmpty(i.tags)) {
						const existedTags = _.filter(i.tags, t => typeof t !== 'object');
						acc = [...acc, ...existedTags];
					} else {
						const existedTags = _.filter(i.tags, t => typeof t !== 'object');
						acc.push(...existedTags);
					}
					return acc;
				},
				([]: string[])
			);

			const uniqTags = _.shuffle(_.uniq(allTagsList));
			if (_.size(uniqTags) > 0) {
				setAllTags(uniqTags);
			}
		} else {
			setAllTags(settings.tagsPopup);
		}

		renderAutocompleteArray();
		filterByURLParams();
	}, []);

	useEffect(() => {
		if (_.size(totalFiltered) <= LOADED_SIZE) {
			setIsEnabledLoadMore(false);
		} else {
			setIsEnabledLoadMore(true);
		}
	}, [totalFiltered]);

	const parseURL = () => {
		if (!history.location.search) {
			return;
		}

		const query = history.location.search.split('?')[1];
		const paramsObj = new URLSearchParams(query);

		if (query) {
			return [...paramsObj].reduce((obj, [key, val]) => {
				obj[key] = val;
				return obj;
			}, {});
		}
	};

	const filterByURLParams = () => {
		const params = parseURL();

		if (params?.search) {
			onSearch(params.search);
		}

		if (params?.year && params.months) {
			const date = {
				year: params.year,
				months: params.months.length >= 1 ? params.months.split(',') : [params.months],
			};

			filterByDate(date);
			setSelectedDate(date);
		}
		if (params?.tag) {
			filterByTag(params?.tag);
		}
	};

	const renderShareResultURL = () => {
		if (history.location.hash && history.location.hash !== 'catalog') {
			return `${history.location.search}#catalog`;
		}
		return `${history.location.search}${history.location.hash}`;
	};

	const updateURL = (params: string) => {
		history.push(params ? `?${params}#catalog` : '');
	};

	const onOpenPopupButtonClick = (type: string) => {
		switch (type) {
			case POP_UP_TYPES.DATEPICKER:
				setIsDatepickerPopupOpened(true);
				break;
			case POP_UP_TYPES.TAGS:
				setIsTagsPopupOpened(true);
				break;
			default:
				break;
		}

		setOverflowHidden();
	};

	const onClosePopup = type => {
		switch (type) {
			case POP_UP_TYPES.DATEPICKER:
				setIsDatepickerPopupOpened(false);
				break;
			case POP_UP_TYPES.TAGS:
				setIsTagsPopupOpened(false);
				break;
			default:
				break;
		}
		resetOverflow();
	};

	const labelForDateGA = (selected: SelectedDate) => {
		const months = [];
		_.forEach(selected.months, monthNumber => {
			const monthInfo = MONTHS.find(month => {
				return month.monthNumber === monthNumber;
			});
			if (monthInfo) {
				months.push(monthInfo.monthName);
			}
		});
		return `${selected.year} ${months.join(' ')}`;
	};

	const onSearch = (value: string) => {
		const filteredItems = _.filter(items, (item: CoverItem) => {
			const searchStr = value.toLowerCase();
			const tagsString = item.tags.join(',').toLowerCase();
			return (
				(item.title && item.title.toLowerCase().includes(searchStr)) ||
				(tagsString && tagsString.includes(searchStr))
			);
		});

		setFilteredList(filteredItems.slice(0, LOADED_SIZE));
		setTotalFiltered(filteredItems);
		setLoadsClickCounter(1);
		if (selectedTag) {
			setSelectedTag('');
		}
		if (selectedDate) {
			setSelectedDate({ months: [], year: '' });
		}

		updateURL(`search=${value}`);
	};

	const renderAutocompleteArray = () => {
		const all = _.reduce(
			items,
			(acc, item, index) => {
				if (item.title) {
					acc.push(item.title);
				}
				if (!_.isEmpty(item.tags)) {
					const existedTags = _.filter(item.tags, t => typeof t !== 'object');
					acc = acc.concat(existedTags);
				}
				return acc;
			},
			[]
		);

		const uniq = _.uniq(all);

		setAutocompleteArray(uniq);
	};

	const filterByDate = (selected: SelectedDate) => {
		if (!IS_DEVEL) {
			const gaLabel = labelForDateGA(selected);
			dataLayerEvents.onSearchDate(gaLabel);
		}
		const filteredByDate = _.reduce(
			items,
			(acc, i, key) => {
				if (i.year === selected.year && selected.months.includes(i.month)) {
					acc.push(i);
				}
				return acc;
			},
			([]: CoverItem[])
		);

		setSelectedDate(selected);
		setFilteredList(_.shuffle(filteredByDate.slice(0, LOADED_SIZE)));
		setTotalFiltered(filteredByDate);
		setLoadsClickCounter(1);
		if (selectedTag) {
			setSelectedTag('');
		}

		updateURL(`year=${selected.year}&months=${selected.months}`);
	};

	const filterByTag = (tag: string) => {
		if (!tag) {
			removeFilter();
			updateURL('');
			return;
		}

		if (!IS_DEVEL) {
			dataLayerEvents.onSearchTag(tag);
		}

		const filteredByTag = _.reduce(
			items,
			(acc, i, key) => {
				if (i.tags.includes(tag)) {
					acc.push(i);
				}
				return acc;
			},
			([]: CoverItem[])
		);

		setFilteredList(_.shuffle(filteredByTag.slice(0, LOADED_SIZE)));

		setTotalFiltered(filteredByTag);
		setSelectedTag(tag);
		setLoadsClickCounter(1);
		if (selectedDate) {
			setSelectedDate({ months: [], year: '' });
		}

		updateURL(`tag=${tag}`);
	};

	const removeFilter = () => {
		setTotalFiltered(items);
		setSelectedTag('');
		setFilteredList(items ? items.slice(0, LOADED_SIZE) : []);
	};

	const onTagClick = (e: SyntheticEvent<HTMLButtonElement>) => {
		const { tag } = e.currentTarget.dataset;
		if (selectedTag === tag) {
			removeFilter();
		} else {
			filterByTag(tag);
		}
	};

	const onLoadButtonClick = () => {
		const counter = loadsClickCounter + 1;
		const loadData = _.isEmpty(totalFiltered)
			? items.slice(_.size(filteredList), counter * LOADED_SIZE)
			: totalFiltered.slice(_.size(filteredList), counter * LOADED_SIZE);
		const maxCount = _.isEmpty(totalFiltered) ? MAX_LOADED_COUNT : Math.ceil(_.size(totalFiltered) / LOADED_SIZE);

		if (counter <= maxCount) {
			setFilteredList([...filteredList, ...loadData]);
			setLoadsClickCounter(counter);

			if (counter === maxCount) {
				setIsEnabledLoadMore(false);
			}
		}
	};

	// const onCatalogItemClick = (e: SyntheticEvent<HTMLElement>) => {
	// 	const { id } = e.currentTarget.dataset;
	// 	const slideIndex = _.findIndex(filteredList, { id });

	// 	setActivePopupCover(true);
	// 	setPopupInitialSlide(slideIndex);
	// };

	const closeCoverPopUp = () => {
		setActivePopupCover(false);
	};

	const setOverflowHidden = () => {
		if (CAN_USE_DOM) {
			if (document.body) {
				document.body.style.overflow = 'hidden';
			}
		}
	};

	const resetOverflow = () => {
		if (CAN_USE_DOM) {
			if (document.body) {
				document.body.style.overflow = '';
			}
		}
	};

	const renderImageSRC = i => {
		return !_.isEmpty(i.uploadedCover)
			? fileLinkIMGIX(i.uploadedCover, imageParams)
			: `https://laishacovers.imgix.net/covers/LAI${i.fileName}.jpg${imageParams}`;
	};

	const onPopupNextButtonClick = () => {
		if (popupSwiper) {
			// +2 to load items before the last one
			if (popupSwiper.realIndex + 2 === filteredList.length && filteredList.length < totalFiltered.length) {
				onLoadButtonClick();
			}
			popupSwiper.slideNext();
		}
	};

	const onPopupPrevButtonClick = () => {
		if (popupSwiper) {
			popupSwiper.slidePrev();
		}
	};

	const handleTouchStart = e => {
		setTouchStartPoint(_.get(e, 'changedTouches[0].clientY', 0));
	};

	const handleTouchEnd = e => {
		const endPoint = _.get(e, 'changedTouches[0].clientY', 0);
		handleTouchMove(endPoint, touchStartPoint);
	};

	const handleTouchMove = (endPoint, startPoint) => {
		if (endPoint < startPoint) {
			setIsScrolledContent(true);
		} else {
			setIsScrolledContent(false);
		}
	};

	return (
		<div className={css.catalog}>
			<h4 className={css.catalogTitle}>{title}</h4>
			<NoSSR>
				<div className={css.searchAndDatepicker}>
					<Search onSearch={onSearch} autocompleteArray={autocompleteArray} />
					<div className={css.tagsFilter}>
						<div className={css.tagsFilterButton} onClick={() => onOpenPopupButtonClick(POP_UP_TYPES.TAGS)}>
							<div className={css.button}>
								<div style={{ backgroundImage: `url(${tagIcon})` }} className={css.tagsIcon} />
								{isDesktop && <div className={css.holder}>{tagsButton}</div>}
							</div>
						</div>
						{isTagsPopupOpened && (
							<TagsPopup
								onClosePopup={() => onClosePopup(POP_UP_TYPES.TAGS)}
								tags={allTags}
								selectedTag={selectedTag}
								setSelectedTag={tag => filterByTag(tag)}
							/>
						)}
					</div>
					<div className={css.datepicker}>
						<div
							className={css.datepickerButton}
							onClick={() => onOpenPopupButtonClick(POP_UP_TYPES.DATEPICKER)}
						>
							{/* {isDesktop ? (
								<div className={css.button}>
									<div className={css.calendarIconWrap}>
										<div
											style={{ backgroundImage: `url(${calendarIcon})` }}
											className={css.calendarIcon}
										/>
									</div>
									<div className={css.yearHolder}>{text}</div>
								</div>
							) : (
								<Icon type="datepicker-button-mobile" />
							)} */}

							<div className={css.button}>
								<div className={css.calendarIconWrap}>
									<div
										style={{ backgroundImage: `url(${calendarIcon})` }}
										className={css.calendarIcon}
									/>
								</div>
								{isDesktop && <div className={css.yearHolder}>{text}</div>}
							</div>
						</div>
						{isDatepickerPopupOpened && (
							<DatePickerPopup
								initialData={selectedDate}
								setSelectedDate={filterByDate}
								onClosePopup={() => onClosePopup(POP_UP_TYPES.DATEPICKER)}
							/>
						)}
					</div>
				</div>
			</NoSSR>

			<div className={css.tagsList}>
				<div className={css.tagsListInner}>
					{_.map(tags, (chunk, index) => {
						return (
							<div className={classNames(css.tagsRow, css[`tagsRow${index}`])} key={index}>
								{_.map(chunk, t => {
									const tag = t.slice(0, 11);
									return (
										<div
											key={t}
											className={classNames(
												css.tagsListItem,
												selectedTag === t && css.tagsListItemSelected
											)}
											data-tag={t}
											// onClick={selectedTag === t ? removeFilter : filterByTag}
											onClick={onTagClick}
										>
											{t.length > 11 ? `${tag}...` : t}
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
			<NoSSR>
				<div className={css.searchSocials}>
					<span className={css.searchSocialsText}>{searchSocialsText}</span>
					<Socialls color="#2f2f2f" isBold options={{ url: renderShareResultURL() }} />
				</div>
			</NoSSR>

			{_.isEmpty(filteredList) && <div className={css.noData}>{NO_DATA}</div>}
			<div className={classNames(css.catalogList, !isEnabledLoadMore && css.isFullList)}>
				{_.map(filteredList, i => {
					return (
						// <div key={i.id} className={css.catalogListItem} data-id={i.id} onClick={onCatalogItemClick}>
						<div key={i.id} className={css.catalogListItem} data-id={i.id}>
							<div className={css.imageWrapper}>
								{/* eslint-disable-next-line react/jsx-no-target-blank */}
								<a
									href={`${COVER_PAGE_ROUTE.replace(':id', i.id)}/${history.location.search}`}
									target="_blank"
									// onClick={e => e.preventDefault()}
									title={`${i.title || i.modelName}`}
								>
									<img
										src={CAN_USE_DOM ? renderImageSRC(i) : DEFAULT_IMAGE}
										alt={`${i.title || i.modelName}`}
									/>
								</a>
							</div>
							{isDesktop && (
								<div className={css.itemHover}>
									{/* eslint-disable-next-line react/jsx-no-target-blank */}
									<a
										href={`${COVER_PAGE_ROUTE.replace(':id', i.id)}/${history.location.search}`}
										target="_blank"
										// onClick={e => e.preventDefault()}
										title={`${i.title || i.modelName}`}
									>
										<div className={css.itemHoverContent}>
											<div className={css.hoverTitle}>
												{i.modelName}, {i.date}
											</div>
											<div className={css.socials}>
												<span className={css.socialsText}>{socialsText}</span>
												<Socialls options={{ url: `${i.id}/` }} />
											</div>
										</div>
									</a>
								</div>
							)}
						</div>
					);
				})}
			</div>
			{isEnabledLoadMore && (
				<div className={css.loadMore}>
					<Button label={loadMoreButtonLabel} className={css.loadMoreButton} onClick={onLoadButtonClick} />
				</div>
			)}
			{activePopupCover && (
				<div
					className={classNames(css.popupSliderWrapper, isScrolledContent && css.isScrolling)}
					onTouchStart={!isDesktop ? handleTouchStart : null}
					onTouchEnd={!isDesktop ? handleTouchEnd : null}
				>
					<Icon type="close-icon" onClick={closeCoverPopUp} className={css.closeIcon} />
					<Swiper
						slidesPerView={1}
						initialSlide={popupInitialSlide}
						speed={1000}
						onSwiper={setPopupSwiper}
						lazy={{ loadPrevNext: true }}
						// onSlideChange={onPopupSlideChange}
						navigation={{
							nextEl: `${css.popupButtonNext}`,
							prevEl: `${css.popupButtonPrev}`,
						}}
						className={css.popupSlider}
					>
						{_.map(initialSlides, i => {
							return (
								<SwiperSlide className={css.slide} key={i.id}>
									<CoverPopUp
										item={i}
										type="slide"
										isScrolling={isScrolledContent}
										slideType="fullHeight"
									/>
								</SwiperSlide>
							);
						})}
					</Swiper>
					<div className={css.popupNavigation}>
						<div className={css.popupButtonPrev} onClick={onPopupPrevButtonClick}>
							<div className={css.popupButtonPrevArrow} />
							<div className={css.navLabel}>הקודם</div>
						</div>
						<div className={css.popupButtonNext} onClick={onPopupNextButtonClick}>
							<div className={css.navLabel}>הבא</div>
							<div className={css.popupButtonNextArrow} />
						</div>
					</div>
				</div>
			)}
			{/* <CoverPopUp item={activePopupCover} type="single" onCloseCoverPopUp={closeCoverPopUp} /> */}
		</div>
	);
};

export default Catalog;
