// @flow
import _ from 'lodash';
import * as actions from 'client/constants/actions';
import * as api from 'api';
import { apiRequestAction } from './helpers/api-request';

export function createItem<I: AbstractItem>(params: CreateItemParams<I>): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.CREATE_ITEM,
			request: api.createItemLead,
			params,
		});

		return dispatch(requestAction);
	};
}

export function getFullCatalog() {
	return async dispatch => {
		try {
			dispatch({ type: 'get_full_catalog_start' });

			const response = await fetch(`https://ynetlaisha-storage.cdn-il.com/api/covers.json`);
			const data = await response.json();

			if (data) {
				dispatch({ type: 'get_full_catalog_success', payload: _.shuffle(data) });
				return data;
			}

			throw new Error('Failed to fetch full catalog data');
		} catch (e) {
			const msg = e.message;
			dispatch({ type: 'get_full_catalog_fail', payload: msg });
		}

		return null;
	};
}

export function getFullMainData() {
	return async dispatch => {
		try {
			dispatch({ type: 'get_full_main_data_start' });

			const response = await fetch(`https://ynetlaisha-storage.cdn-il.com/api/main.json`);
			const data = await response.json();

			if (data) {
				dispatch({
					type: 'get_full_celebs_data_success',
					payload: {
						...data.celebs,
						items: _.shuffle(data.celebs.items),
					},
				});
				return data;
			}

			throw new Error('Failed to fetch full main data');
		} catch (e) {
			const msg = e.message;
			dispatch({ type: 'get_full_main_data_fail', payload: msg });
		}

		return null;
	};
}
