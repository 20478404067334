// @flow

import produce from 'immer';
import _ from 'lodash';

import * as actions from 'client/constants/actions';

type Action = {
	type: string,
	payload: any,
};

type Catalog = {
	data: {
		list: CoverItem[],
		total: number,
		collection: string,
	},
	isBusy: false,
};

const initialState: Catalog = {
	isBusy: false,
	data: { total: 0, list: [], collection: '' },
};

export default function catalogReducer(state: Catalog = initialState, { type, payload }: Action) {
	return produce(state, draft => {
		switch (type) {
			case actions.GET_CATALOG_ITEMS.START:
				_.set(draft, 'isBusy', true);
				break;

			case actions.GET_CATALOG_ITEMS.SUCCESS:
				_.set(draft, 'data', payload);
				_.set(draft, 'isBusy', false);
				break;

			case 'get_full_catalog_success': {
				_.each(payload, item => {
					if (draft.data.find(i => i.id === item.id)) {
						return;
					}

					draft.data.push(item);
				});
				break;
			}

			case actions.GET_CATALOG_ITEMS.FAIL:
				_.set(draft, 'isBusy', false);
				break;

			default:
				break;
		}
	});
}
