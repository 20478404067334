// @flow
// flow-disable-next-line
export const APP_CONFIG = APPLICATION_CONFIG; // eslint-disable-line
export const ORIGINAL_IMAGE_IDENTIFIER = '_xoriginal';

export const APP_CONTAINER_CLASS = 'root';
export const APP_CONTAINER_SELECTOR = `.${APP_CONTAINER_CLASS}`;
export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const IMAGE_SIZES = {
	small: {
		width: 550,
	},
	large: {
		width: 908,
	},
};

export const LEAD_CONSTANTS = {
	CREATED: 'created',
	FOUNDED: 'founded',
};

// Image - 1x1 px
export const DEFAULT_IMAGE =
	// eslint-disable-next-line
	'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=';
